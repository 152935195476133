import React, { useContext } from "react";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/baby/diaper-rash/diaper-paste-product-front.webp";
import backImage from "images/baby/diaper-rash/diaper-paste-product-back.webp";
import frontZoomImage from "images/baby/diaper-rash/diaper-paste-product-front-zoom.webp";
import backZoomImage from "images/baby/diaper-rash/diaper-paste-product-back-zoom.webp";

import img2 from "images/baby/diaper-rash/aquaphor-diaper-rash-paste-clinical.webp";
import img3 from "images/baby/diaper-rash/diaper-paste-resource.webp";
import img4 from "images/baby/general-care/aquaphor-ointment-callout-footer.webp";
import img5 from "images/callouts/cout-diapercream-footer.webp";

import "./aquaphordiaperrashpaste.scss";

function AquaphorDiaperPastePage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-baby-diaperrash page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Aquaphor Baby Diaper Rash Paste"
        pageDescription="Learn about Aquaphor Baby Diaper Rash Paste"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#363084"
              categoryName="DIAPER RASh"
              categoryFontColor="#ffffff"
              productName="Aquaphor<sup>®</sup> Baby Diaper Rash Paste"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold metioriteblue-text sub-title">
                  Helps treat moderate to severe diaper rash
                </p>
                <p>
                  <span className="font-semi-bold">
                    Aquaphor Baby Diaper Rash Paste
                  </span>{" "}
                  is a thick, easy-to-apply paste that stays on all night and
                  helps effectively treat moderate to severe diaper rash.
                </p>
                <ul className="metiorite-bullets">
                  <li>
                    <span>
                      Relieves discomfort in one application, plus provides{" "}
                      <nobr>long-lasting</nobr> protection
                    </span>
                  </li>
                  <li>
                    <span className="font-semi-bold">
                      Clinically demonstrated to improve diaper rash and
                      irritation in as little as 24 hours<sup>1</sup>
                    </span>
                  </li>
                  <li>
                    <span>
                      Formulated with maximum strength 40% zinc oxide (skin
                      protectant) to soothe and treat diaper rash flare-ups
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold metioriteblue-text sub-title">
                  Active ingredient
                </p>
                <p className="font-semi-bold">40% zinc oxide</p>
                <ul className="metiorite-bullets">
                  <li>
                    <span>Skin protectant</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold metioriteblue-text sub-title">
                  Inactive ingredients
                </p>
                <p>
                  Glycerin, caprylic/capric triglyceride, octyldodecanol,
                  polyglyceryl-4 diisostearate/polyhydroxystearate/
                  <wbr />
                  sebacate, magnesium stearate, panthenol, butyrospermum parkii
                  (shea) butter, water
                </p>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold metioriteblue-text sub-title">
                    Formulated for long-lasting protection
                  </p>
                  <ul className="checkmarks metioriteblue">
                    <li>
                      <span>HYPOALLERGENIC</span>
                    </li>
                    <li>
                      <span>ODOR-FREE</span>
                    </li>
                    <li>
                      <span>TALC-FREE</span>
                    </li>
                    <li>
                      <span>FRAGRANCE-FREE</span>
                    </li>
                    <li>
                      <span>PRESERVATIVE-FREE</span>
                    </li>
                  </ul>
                </div>
                <p className="footnotes">
                  <span className="font-bold">Reference: 1.</span> Data on file.
                  Beiersdorf Inc.
                </p>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="CLINICAL RESULTS"
              productThemeColor="baby-metiorite-blue"
              id="clinicalresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Graph with improvement in diaper rash severity and symptoms after 1 diaper change and 1 day"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Multicenter
                    clinical trial to assess the efficacy and tolerability of
                    Aquaphor Diaper Rash Paste when used on infants with mild to
                    severe diaper rash (n=28) over the course of 5 days.
                    Subjects were assessed at baseline, 3 hours after a single
                    use, after 1 day, and after 5 days of regular use.
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Statistically <br className="show-desktop" />
                    significant <br className="show-desktop" />
                    improvement in <br className="show-desktop" />
                    diaper rash severity <br className="show-desktop" />
                    and symptoms <br className="show-desktop" /> after 1 diaper{" "}
                    <br className="show-desktop" /> change and 1 day<sup>1</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg section-support-resource purple">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                <TypeC
                  buttonText="DOWNLOAD HERE"
                  buttonUrl={getUrlFromSlug(
                    "aquaphor-baby-diaper-rash-paste",
                    state?.sanityData?.allResources
                  )}
                  className="center-v"
                  isExternalLink={true}
                  targetOpen="_blank"
                >
                  <p className="reg-text-big">
                    Support your recommendation <br className="show-desktop" />{" "}
                    with this resource
                  </p>
                  <p className="reg-text">
                    Review the clinical data for diaper rash paste with parents.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                <img
                  src={img3}
                  alt="Clinical Data for Diaper Rash Paste"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img4}
                  imgAlt="AQUAPHOR® BABY HEALING OINTMENT"
                  caption="Helps prevent diaper rash with <br class='show-desktop' /> every diaper change"
                  buttonText="AQUAPHOR<sup>®</sup> BABY HEALING OINTMENT"
                  buttonUrl="/baby/generalcare/aquaphorbabyhealingointment"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img5}
                  imgAlt="AQUAPHOR<sup>®</sup> BABY 3-IN-1 CREAM"
                  caption="Helps prevent, soothe, and treat <br class='show-desktop' /> mild to moderate diaper rash"
                  buttonText="AQUAPHOR<sup>®</sup> BABY 3-IN-1 CREAM"
                  buttonUrl="/baby/diaperrash/aquaphordiaperrashcream"
                  imgStyles={{ height: 165 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AquaphorDiaperPastePage;
